import { Grid, Pagination, Table, TableBody, TableContainer, TableRow } from "@mui/material";
import { RadioButtonComponent } from "@syncfusion/ej2-react-buttons";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import {
  ColumnDirective,
  ColumnsDirective,
  ExcelExport,
  GridComponent,
  Inject,
  Resize,
  Toolbar,
} from "@syncfusion/ej2-react-grids";
import TitleDiv from "components/Common/TitleDiv";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { BodyTableCell, FormButton, FormDropDown, FormTextBox, TitleTableCell } from "style/theme";
import CRMApi from "system/api/CRMApi";
import UserApi from "system/api/UserApi";
import { ErrorHandler } from "system/ApiService";
import { useLoadingDispatch } from "system/context/LoadingContext";
import { SearchDeliveryType } from "system/CRMConstants";
import { Branch, Delivery, GetInitSearchResult, INIT_SEARCHDELIVERY, SearchDelivery, SearchResult } from "system/types";

function DeliveryMain() {
  const Loading = useLoadingDispatch();
  const [searchForm, setSearchForm] = useState<SearchDelivery>(INIT_SEARCHDELIVERY);
  const [delivery, setDelivery] = useState<SearchResult<Delivery>>(GetInitSearchResult([]));
  const [branch, setBranch] = useState<Branch[]>([]);

  useEffect(() => {
    UserApi.GetBranchList()
      .then((res) => {
        setBranch(res);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        console.log(msg);
      });
  }, []);

  const onSearch = () => {
    CRMApi.GetDelivery(searchForm)
      .then((res) => {
        setDelivery(res);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        console.log(msg);
      });
  };

  const onClickSearch = () => {
    setSearchForm({ ...searchForm, page: 1 });
    onSearch();
  };

  useEffect(() => {
    onSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchForm.page]);

  const onChangeSearch = (e: any) => {
    setSearchForm({ ...searchForm, [e.target.name]: e.target.value === null ? "" : e.target.value });
  };

  const onChangeDate = (e: any) => {
    const { name, value } = e.target;
    let formattedDate = "";

    if (value) {
      let parsedDate;

      // Check if the input is a 6-digit string like "YYMMDD"
      if (/^\d{6}$/.test(value)) {
        // Assume the first two digits are the year
        const year = value.substring(0, 2);
        const month = value.substring(2, 4);
        const day = value.substring(4, 6);

        // Convert "22" to "2022"
        const fullYear = year.length === 2 ? `20${year}` : year;

        // Create a moment date from parsed values
        parsedDate = moment(`${fullYear}-${month}-${day}`, "YYYY-MM-DD", true);
      } else {
        // Parse other date formats
        parsedDate = moment(value, ["YYYY-MM-DD", "YY-MM-DD", "YYYYMMDD", "YYMMDD"], true);
      }

      // If valid, format to "YYYY-MM-DD"
      if (parsedDate.isValid()) {
        formattedDate = parsedDate.format("YYYY-MM-DD");
      }
    }

    // Update state
    setSearchForm({ ...searchForm, [name]: formattedDate });
  };

  const onPageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setSearchForm({ ...searchForm, page: page });
  };

  // 엑셀 출력
  const areSpecificFieldsEmpty = (obj: SearchDelivery) => {
    return (
      (!obj.startDate || obj.startDate === "" || obj.startDate === "Blank") &&
      (!obj.endDate || obj.endDate === "" || obj.endDate === "Blank")
    );
  };

  const onExcel = async () => {
    const today = moment().format("YYYY-MM-DD");
    const oneYearAgo = moment().subtract(1, "year").format("YYYY-MM-DD");

    const searchFormExcel = {
      ...searchForm,
    };

    if (areSpecificFieldsEmpty(searchForm)) {
      const userConfirmed = window.confirm(
        "날짜 필드가 비어 있습니다. 최근 1년 데이터만 조회됩니다. 계속하시겠습니까?"
      );

      if (!userConfirmed) {
        return;
      }
      searchFormExcel.sales = "0";
      searchFormExcel.startDate = oneYearAgo;
      searchFormExcel.endDate = today;
    }

    try {
      Loading({ type: "LOADING_MESSAGE", message: "Downloading..." });
      const response = await fetch("/api/crm/delivery/excel", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${UserApi.GetToken()}`,
        },
        body: JSON.stringify(searchFormExcel),
      });

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Delivery리스트_" + moment().format("YYYYMMDD") + ".xls";
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      } else {
        console.error("Failed to generate file");
      }
    } catch (err) {
      console.log("Error : ", err);
    } finally {
      Loading({ type: "COMPLETE" });
    }
  };

  const progressTemplate = useCallback((props: any): any => {
    if (props.salesEndStatus === 0) {
      return <>{"납품대기"}</>;
    } else {
      return <>{"납품완료"}</>;
    }
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TitleDiv title="Delivery"></TitleDiv>
      </Grid>
      <Grid item xs={8}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TitleTableCell align="center">항목</TitleTableCell>
                <BodyTableCell align="center" style={{ display: "flex" }}>
                  <FormDropDown
                    name="classify"
                    value={searchForm.classify}
                    onChange={onChangeSearch}
                    cssClass="e-outline"
                    width={"120px"}
                    fields={{ text: "key", value: "label" }}
                    dataSource={SearchDeliveryType}
                  ></FormDropDown>
                  <FormTextBox
                    name="text"
                    value={searchForm.text}
                    onChange={onChangeSearch}
                    cssClass="e-outline"
                  ></FormTextBox>
                </BodyTableCell>
                <BodyTableCell colSpan={2}>
                  <Grid style={{ display: "flex", justifyContent: "space-around" }}>
                    <RadioButtonComponent
                      name="setup"
                      value="2"
                      checked={searchForm.setup === "2"}
                      onChange={onChangeSearch}
                      label="전체"
                    ></RadioButtonComponent>
                    <RadioButtonComponent
                      name="setup"
                      value="1"
                      checked={searchForm.setup === "1"}
                      onChange={onChangeSearch}
                      label="납품완료"
                    ></RadioButtonComponent>
                    <RadioButtonComponent
                      name="setup"
                      value="0"
                      checked={searchForm.setup === "0"}
                      onChange={onChangeSearch}
                      label="납품예정"
                    ></RadioButtonComponent>
                  </Grid>
                </BodyTableCell>
              </TableRow>
              <TableRow>
                <TitleTableCell align="center">날짜</TitleTableCell>
                <BodyTableCell style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                  <DatePickerComponent
                    name="startDate"
                    onChange={onChangeDate}
                    onBlur={onChangeDate}
                    value={new Date(searchForm.startDate)}
                    cssClass="e-outline"
                    format="yy-MM-dd"
                    width={"120px"}
                  ></DatePickerComponent>
                  <span> ~ </span>
                  <DatePickerComponent
                    name="endDate"
                    onChange={onChangeDate}
                    onBlur={onChangeDate}
                    value={new Date(searchForm.endDate)}
                    min={new Date(searchForm.startDate)}
                    cssClass="e-outline"
                    format="yy-MM-dd"
                    width={"120px"}
                  ></DatePickerComponent>
                  <RadioButtonComponent
                    name="sales"
                    value="0"
                    checked={searchForm.sales === "0"}
                    onChange={onChangeSearch}
                    label="설치요청일"
                  ></RadioButtonComponent>
                  <RadioButtonComponent
                    name="sales"
                    value="1"
                    checked={searchForm.sales === "1"}
                    onChange={onChangeSearch}
                    label="설치일"
                  ></RadioButtonComponent>
                </BodyTableCell>
                <TitleTableCell style={{ width: "100px" }} align="center">
                  판매지사
                </TitleTableCell>
                <BodyTableCell align="center">
                  <FormDropDown
                    dataSource={branch}
                    name="branch"
                    value={searchForm.branch}
                    fields={{ text: "branchName", value: "branchName" }}
                    onChange={onChangeSearch}
                    cssClass="e-outline"
                    showClearButton={true}
                  ></FormDropDown>
                </BodyTableCell>
                <BodyTableCell align="center">
                  <FormButton onClick={onExcel}>엑셀</FormButton>
                  <FormButton onClick={onClickSearch}>조회</FormButton>
                </BodyTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={4}></Grid>
      <Grid item xs={12}>
        <GridComponent id="grid" dataSource={delivery.list} allowResizing={true}>
          <ColumnsDirective>
            <ColumnDirective
              field="salesId"
              headerText="문서번호"
              textAlign="Center"
              headerTextAlign="Center"
              width={150}
            />
            <ColumnDirective
              field="custName"
              headerText="상호명"
              textAlign="Center"
              headerTextAlign="Center"
              width={150}
            />
            <ColumnDirective
              field="address"
              headerText="주소"
              textAlign="Center"
              headerTextAlign="Center"
              width={300}
            />
            <ColumnDirective
              field="phone"
              headerText="전화번호"
              textAlign="Center"
              headerTextAlign="Center"
              width={150}
            />
            <ColumnDirective
              field="parentCategoryName"
              headerText="제품군"
              textAlign="Center"
              headerTextAlign="Center"
              width={100}
            />
            <ColumnDirective
              field="categoryName"
              headerText="제품명"
              textAlign="Center"
              headerTextAlign="Center"
              width={150}
            />
            <ColumnDirective field="serial" headerText="SN" textAlign="Center" headerTextAlign="Center" width={150} />
            <ColumnDirective
              field="setupTeam"
              headerText="판매지사"
              textAlign="Center"
              headerTextAlign="Center"
              width={150}
            />
            <ColumnDirective
              field="setupResponsible"
              headerText="영업사원"
              textAlign="Center"
              headerTextAlign="Center"
              width={100}
            />
            <ColumnDirective
              field="lineamount"
              headerText="총판매가"
              textAlign="Center"
              headerTextAlign="Center"
              width={100}
            />
            <ColumnDirective
              field="salesDate"
              headerText="설치요청일"
              textAlign="Center"
              headerTextAlign="Center"
              width={150}
            />
            <ColumnDirective
              field="salesDate"
              headerText="설치일"
              textAlign="Center"
              headerTextAlign="Center"
              width={150}
            />
            <ColumnDirective
              field="salesEndStatus"
              headerText="진행상황"
              textAlign="Center"
              headerTextAlign="Center"
              width={100}
              template={progressTemplate}
            />
          </ColumnsDirective>
          <Inject services={[Toolbar, ExcelExport, Resize]} />
        </GridComponent>
        <Pagination
          count={delivery.totalPage}
          page={searchForm.page}
          onChange={onPageChange}
          showFirstButton
          showLastButton
        />
      </Grid>
    </Grid>
  );
}

export default DeliveryMain;
