export const SearchDeliveryType = [
  { key: "상호명", label: "customerName" },
  { key: "S/N", label: "serial" },
  { key: "주소", label: "address" },
  { key: "전화번호", label: "tel" },
];
export const SearchItemType = [
  { key: "상호명", label: "customerName" },
  { key: "S/N", label: "serial" },
  { key: "주소", label: "address" },
  { key: "전화번호", label: "tel" },
  { key: "접수자", label: "recepName" },
  { key: "처리자", label: "manager" },
  { key: "문서번호", label: "reportNum" },
  { key: "자재", label: "item" },
];
export const SearchDateType = [
  { key: "접수일", label: "receivedDate" },
  { key: "처리일", label: "completedDate" },
  { key: "수정일", label: "createdAt" },
];
export const SearchDateType2 = [
  { key: "접수일", label: "receivedDate" },
  { key: "처리일", label: "completedDate" },
  { key: "방문예정일", label: "estimatedDateofVist" },
  { key: "수정일", label: "createdAt" },
];
export const SearchCSRType = [
  { key: "상호명", label: "customerName" },
  { key: "S/N", label: "serial" },
  { key: "문서번호", label: "reportNum" },
  { key: "주소", label: "address" },
  { key: "전화번호", label: "tel" },
];
export const SearchStatusType = [
  { key: "전체", label: 2 },
  { key: "발행", label: 1 },
  { key: "미발행", label: 0 },
];
// ERP 기준정보
export const HandlingMethod = [
  { key: "선택", label: "Blank" },
  { key: "방문", label: "Visit" },
  { key: "전화", label: "Call" },
  { key: "수거", label: "Collection" },
  { key: "택배", label: "Parcel" },
];
export const CallResult = [
  { key: "선택", label: "Blank" },
  { key: "전화처리", label: "Call" },
  { key: "CS접수", label: "CS" },
  { key: "원격처리", label: "Remote" },
  { key: "전화미처리", label: "NotCall" },
  { key: "지사문의", label: "Branch" },
  { key: "지사전달", label: "Movetobranch" },
  { key: "자재처리", label: "Material" },
  { key: "임상처리", label: "Clinical" },
  { key: "본사전달", label: "MovetoHQ" },
];
export const ServiceBillType = [
  { key: "유상", label: "Charge" },
  { key: "무상", label: "NonCharge" },
  { key: "초기", label: "Initial" },
];
export const ResultForVisit = [
  { key: "선택", label: "Blank" },
  { key: "수리보류", label: "Repairpending" },
  { key: "보상진행", label: "CompensationProgress" },
  { key: "모니터링", label: "Monitoring" },
  { key: "보드 및 장비입고", label: "BoardAndEquipmentloading" },
  { key: "점검완료", label: "InspectionComplete" },
  { key: "택배발송", label: "DeliveryByCourier" },
  { key: "장비교체", label: "EquipmentReplacement" },
  { key: "회수완료", label: "RecoveryComplete" },
];
export const PaymentType = [
  { key: "선택", label: "Blank" },
  { key: "입금", label: "Deposit" },
  { key: "현금", label: "Cash" },
  { key: "카드", label: "Card" },
  { key: "무상", label: "Free" },
  { key: "반품", label: "Returns" },
  { key: "입금대기", label: "WaitingForDeposit" },
  { key: "모니터링", label: "Monitoring" },
];
export const FOCReason = [
  { key: "선택", label: "Blank" },
  { key: "고객클레임", label: "CustomerClaim" },
  { key: "데모장비 수리", label: "DemonstrationEquipmentRepair" },
  { key: "보상진행", label: "CompensationProgress" },
  { key: "수리 보류", label: "RepairPending" },
  { key: "이전과 동일문제", label: "SameProblemasBefore" },
  { key: "재방문 시 비용 청구", label: "ChargeForReturnVisits" },
  { key: "장비 2대 점검 후 비용 같이 청구함", label: "AfterInspecting2PiecesOfEquipment_TheCostIsChargedTogether" },
  { key: "영업요청", label: "SalesRequest" },
  { key: "무상처리 내", label: "WithinFreeProcessing" },
];
// 연동 X
export const MedicalAccident = [
  { key: "해당사항없음", label: false },
  { key: "해당사항있음", label: true },
];
export const Payment = ["해당없음", "미납", "수거"];
export const ReceiptSendType = ["필요없음", "발송필요", "발송완료"];

// 문자 전송 타입
export const SEND_MESSAGE_TYPE = [
  { key: "일반메시지 보내기", label: "normal" },
  { key: "CS제품 입고 안내", label: "IB021" },
  { key: "CS점검 비용 안내", label: "IB024" },
  { key: "인바디 계좌정보", label: "IB025" },
  { key: "CJ택배 발송 안내", label: "IB054" },
  { key: "H20 입고AS 안내 및 H30 보상안내", label: "IB051" },
];
export const SEND_MESSAGE_CONTENT: { [key: string]: string } = {
  normal: "",
  IB021:
    "안녕하세요 #{수신자명}님\n고객님께서 보내주신 인바디제품이 CS팀에 입고되었습니다. \n대기순서에 따라 고객님의 제품 점검이 진행될 예정입니다.\n감사합니다.\n",

  IB024:
    "안녕하세요 #{수신자명}님\n고객님의 인바디 제품 점검 비용 안내드립니다.\n입금은행:우리은행\n1005-601-020129\n예금주:㈜인바디\n입금액:#{AS금액}원\n* 입금 확인 완료 후 AS제품 택배발송 예정입니다.\n* AS문의:1899-5841\n",

  IB025:
    "안녕하세요, 인바디입니다.\n인바디 계좌정보 안내드립니다.\n\n입금은행:우리은행\n1005-601-020129\n예금주:㈜인바디\n\n* 고객님 성함으로 입금 부탁드립니다. 감사합니다.\n* AS문의:1899-5841\n",

  IB054:
    "안녕하세요 #{수신자명}님\n고객님의 제품 점검이 완료되어 금일 발송 예정입니다.\n송장번호는 CJ택배 #{송장번호}입니다.\n감사합니다.\n",
  IB051:
    "안녕하세요 인바디입니다.\n고객님의 AS물품이 회수 접수되었습니다.\n\n대한통운사에서 주말 제외 2~3일 내 회수 예정입니다.\n제품은 이동 간 파손되지 않도록 완충 포장하시어 택배사 전달해주시길 바랍니다.\n\n감사합니다.\n\n\n* 신제품 H30 AS보상 교환 안내\n: AS접수 물품을 수리가 아닌 반납 후 신제품 H30 보상 구매 진행이 가능합니다.\n기존 AS제품은 폐기로 진행되며, 구입일 기준 무상 기간 2년이 적용됩니다.\n보상 구매를 원하시는 경우 고객 센터에 문의해 주시기 바랍니다.\n[H30 Wifi / H30N 가격]\n - H30 Wifi - 280, 000원 / H30N - 250, 000원 입니다",
};
