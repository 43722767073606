import fileDownload from "js-file-download";
import { FormButton } from "style/theme";
import CRMApi from "system/api/CRMApi";

interface PrintButtonGroupProps {
  caseId: string;
}

function PrintButtonGroup({ caseId }: PrintButtonGroupProps) {
  const onEstimate = () => {
    CRMApi.DownloadEstimate(caseId)
      .then((res) => {
        fileDownload(res, `견적서_${caseId}.xlsx`);
      })
      .catch((error) => {
        console.error("Error downloading trading file:", error);
      });
  };

  const onTrading = () => {
    CRMApi.DownloadTrading(caseId)
      .then((res) => {
        fileDownload(res, `거래명세서_${caseId}.xlsx`);
      })
      .catch((error) => {
        console.error("Error downloading trading file:", error);
      });
  };

  const onInspect = () => {
    CRMApi.DownloadInspect(caseId)
      .then((res) => {
        fileDownload(res, `점검내역서_${caseId}.xlsx`);
      })
      .catch((error) => {
        console.error("Error downloading trading file:", error);
      });
  };

  return (
    <>
      <FormButton onClick={onEstimate}>견적서</FormButton>
      <FormButton onClick={onTrading}>거래명세서</FormButton>
      <FormButton onClick={onInspect}>점검내역서</FormButton>
    </>
  );
}

export default PrintButtonGroup;
