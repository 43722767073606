import { Grid, Table, TableBody, TableContainer, TableRow, Typography } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import "style/form.css";
import { BodyTableCell, FormButton, FormDropDown, FormTextBox, TitleTableCell } from "style/theme";
import CRMApi from "system/api/CRMApi";
import { ErrorHandler } from "system/ApiService";
import { SEND_MESSAGE_CONTENT, SEND_MESSAGE_TYPE } from "system/CRMConstants";
import { Contact, CSR, Message } from "system/types";

interface ReportMessageProps {
  msg: Message;
  csr: CSR;
}

function Validation(data: Message, recipientName: string, asCost: string, invoiceNumber: string) {
  if ((data.type === "IB021" || data.type === "IB024" || data.type === "IB054") && recipientName === "") {
    return false;
  } else if (data.type === "IB024" && asCost === "") return false;
  else if (data.type === "IB054" && invoiceNumber === "") return false;
  else if (data.content === "") return false;
  return true;
}

function ReportMessage({ msg, csr }: ReportMessageProps) {
  const [data, setData] = useState<Message>(msg);
  const [searchPhone, setSearchPhone] = useState("");
  const [list, setList] = useState<Message[]>([]);
  const fields: object = { text: "key", value: "label" };

  // 추가된 상태 필드
  const [recipientName, setRecipientName] = useState<string>("");
  const [asCost, setAsCost] = useState<string>("");
  const [invoiceNumber, setInvoiceNumber] = useState("");

  useEffect(() => {
    const primaryContact = csr.custInfo.contactInfo.find((contact: Contact) => contact.isPrimary === "Yes");
    const custPhone = primaryContact ? primaryContact.locator : csr.custInfo.contactInfo[0]?.locator || "";
    setRecipientName(csr.custInfo.custName);
    setData({ ...msg, phone: custPhone });
    setAsCost(csr.soList.reduce((total, so) => (total = total + so.salesPrice), 0).toLocaleString());

    if (custPhone !== "") {
      CRMApi.GetMessageList(custPhone)
        .then((res) => {
          setList(res);
          setSearchPhone(custPhone);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert(msg);
        });
    } else {
      setList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [csr.custInfo.contactInfo, csr.custInfo.custName, msg]);

  const onSearchClick = () => {
    if (searchPhone !== "") {
      CRMApi.GetMessageList(searchPhone)
        .then((res) => {
          setList(res);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert(msg);
        });
    } else {
      setList([]);
    }
  };

  const onSearchChange = (args: any) => {
    setSearchPhone(args.target.value);
  };

  const onChange = (args: any) => {
    if (args.target.name === "type") {
      setData({
        ...data,
        [args.target.name]: args.target.value,
        content: SEND_MESSAGE_CONTENT[args.target.value],
      });
    } else {
      setData({ ...data, [args.target.name]: args.target.value });
    }

    if (args.target.name === "recipientName") {
      setRecipientName(args.target.value);
    } else if (args.target.name === "asCost") {
      setAsCost(args.target.value);
    } else if (args.target.name === "invoiceNumber") {
      setInvoiceNumber(args.target.value);
    }
  };

  const handleReportMessage = () => {
    if (data.phone !== "" && Validation(data, recipientName, asCost, invoiceNumber)) {
      const phoneNumbers = Array.from(new Set(data.phone.split(",").map((num) => num.trim())));
      let updatedContent = data.content.replace("#{수신자명}", recipientName);
      updatedContent = updatedContent.replace("#{AS금액}", asCost);
      updatedContent = updatedContent.replace("#{송장번호}", invoiceNumber);

      // 각 전화번호에 대해 API 호출
      const sendPromises = phoneNumbers.map((phone) => {
        const messageData = { ...data, phone };
        messageData.content = updatedContent;
        return CRMApi.SendMessage(messageData);
      });

      Promise.all(sendPromises)
        .then((res) => {
          alert(res + "문자 전송이 완료되었습니다.");
          setData({ ...data, content: "" });
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert(msg);
        });
    } else {
      alert("전화번호, 내용을 입력해주세요.");
    }
  };

  const handleSendEmail = () => {
    if (data.email && Validation(data, recipientName, asCost, invoiceNumber) && data.title) {
      let updatedContent = data.content.replace("#{수신자명}", recipientName);
      updatedContent = updatedContent.replace("#{AS금액}", asCost);
      updatedContent = updatedContent.replace("#{송장번호}", invoiceNumber);
      data.content = updatedContent;

      CRMApi.SendEmail(data)
        .then((res) => {
          alert(res + "메일 전송이 완료 되었습니다.");
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          alert(msg);
        });
    } else alert("제목, 이메일, 필수값을 입력해주세요.");
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TitleTableCell style={{ width: "80px" }} align="center">
                  제목
                </TitleTableCell>
                <BodyTableCell>
                  <FormTextBox name="title" cssClass="e-outline" value={data.title} onChange={onChange}></FormTextBox>
                </BodyTableCell>
              </TableRow>
              <TableRow>
                <TitleTableCell align="center">전화번호</TitleTableCell>
                <BodyTableCell>
                  <FormTextBox name="phone" cssClass="e-outline" value={data.phone} onChange={onChange}></FormTextBox>
                </BodyTableCell>
              </TableRow>
              <TableRow>
                <TitleTableCell align="center">이메일</TitleTableCell>
                <BodyTableCell>
                  <FormTextBox name="email" cssClass="e-outline" value={data.email} onChange={onChange}></FormTextBox>
                </BodyTableCell>
              </TableRow>
              <TableRow>
                <TitleTableCell align="center">메세지 타입</TitleTableCell>
                <BodyTableCell>
                  <FormDropDown
                    name="type"
                    cssClass="e-outline"
                    fields={fields}
                    dataSource={SEND_MESSAGE_TYPE}
                    onChange={onChange}
                    value={data.type}
                  ></FormDropDown>
                </BodyTableCell>
              </TableRow>
              {(data.type === "IB021" || data.type === "IB024" || data.type === "IB054") && (
                <TableRow>
                  <TitleTableCell align="center">수신자명</TitleTableCell>
                  <BodyTableCell>
                    <FormTextBox
                      name="recipientName"
                      cssClass="e-outline"
                      value={recipientName}
                      onChange={onChange}
                    ></FormTextBox>
                  </BodyTableCell>
                </TableRow>
              )}
              {data.type === "IB024" && (
                <TableRow>
                  <TitleTableCell align="center">AS금액</TitleTableCell>
                  <BodyTableCell>
                    <FormTextBox name="asCost" cssClass="e-outline" onChange={onChange} value={asCost}></FormTextBox>
                  </BodyTableCell>
                </TableRow>
              )}
              {data.type === "IB054" && (
                <TableRow>
                  <TitleTableCell align="center">송장번호</TitleTableCell>
                  <BodyTableCell>
                    <FormTextBox
                      name="invoiceNumber"
                      cssClass="e-outline"
                      onChange={onChange}
                      value={invoiceNumber}
                    ></FormTextBox>
                  </BodyTableCell>
                </TableRow>
              )}
              <TableRow>
                <TitleTableCell align="center">내용</TitleTableCell>
                <BodyTableCell>
                  <textarea
                    name="content"
                    style={{ width: "100%", minHeight: "150px", resize: "vertical" }}
                    value={data.content}
                    onChange={onChange}
                  ></textarea>
                </BodyTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Typography variant="body2">*문자는 ,(콤마)를 기준으로 전송됩니다.</Typography>
        <Typography variant="body2">*문자를 보내면 내용이 지워집니다.(메일은 지워지지 않습니다.)</Typography>
        <Typography variant="body2">
          *메일 보내기는 (상담문의:문자양식 자동완성)의 양식을 무조건 따라야 합니다
        </Typography>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <FormButton onClick={handleReportMessage}>문자 보내기</FormButton>
          <FormButton onClick={handleSendEmail}>메일 보내기</FormButton>
        </div>
      </Grid>
      <Grid item xs={5}>
        <br />
        <Typography variant="body2">최근 한 달간의 내용을 가져옵니다.</Typography>
        <Typography variant="body2">실제로 고객이 메세지를 받으면 아래에서 확인할 수 있습니다.</Typography>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <BodyTableCell align="center">내역 조회하기</BodyTableCell>
                <BodyTableCell align="center" style={{ display: "flex", alignItems: "center" }}>
                  <FormTextBox
                    name="searchPhone"
                    cssClass="e-outline"
                    value={searchPhone}
                    onChange={onSearchChange}
                  ></FormTextBox>
                  <FormButton onClick={onSearchClick}>조회</FormButton>
                </BodyTableCell>
              </TableRow>
              <TableRow>
                <TitleTableCell align="center">보낸 날짜</TitleTableCell>
                <TitleTableCell align="center">메세지 내용</TitleTableCell>
              </TableRow>
              {list.map((item: Message) => (
                <TableRow key={item.id}>
                  <BodyTableCell align="center">{moment(item.created).format("MM-DD HH:mm")}</BodyTableCell>
                  <BodyTableCell>{item.content}</BodyTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

export default ReportMessage;
